import { ReactComponent as StarFull } from "../images/star-full.svg"
import { ReactComponent as StarHalf } from "../images/star-half.svg"
import { ReactComponent as StarEmpty } from "../images/star-empty.svg"

export const CardProfData = ({ topicName, meetingLink, profImage, stars, onSelectMeeting }) => {
     return (
          <>
               <div className={`topic-data`}>
                    <div className='topic-image'>
                         <img src={profImage} alt='' />
                    </div>
                    <div className='topic-info'>
                         <div className='topic-card-middle'>
                              <strong className='topic-name'>{topicName}</strong>
                              <p className='topic-level'>Difficulty level</p>
                              <div className='topic-star-container'>
                                   {stars.map((star, index) => {
                                        if (star === 0) {
                                             return <StarEmpty key={index} />
                                        }
                                        if (star === 1) {
                                             return <StarFull key={index} />
                                        }
                                        return <StarHalf key={index} />
                                   })}
                              </div>
                         </div>
                    </div>
               </div>
               <button
                    className='a-button'
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() => onSelectMeeting(meetingLink)}
               >
                    Schedule
               </button>
          </>
     )
}
