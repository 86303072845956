import { useCallback, useEffect, useState } from "react";

import "./sass/App.scss";

//Components
import Verification from "./components/verification/Verification";
import Questionnaire from "./components/Questionnaire";
import Preliminaries from "./components/preliminaries/Preliminaries";
import Scheduler from "./components/Scheduler";

//Context
import { DataLenghtContext } from "./contexts/DataLenghtContext";
import { UserChoicesContext } from "./contexts/UserChoicesContext";
import { UserInfoContext } from "./contexts/UserInfoContext";

//Helpers
import { activateScreenSizeChangeDetection } from "./helpers/screenSizeChangeDetector";
import { calculateScore, generateResultStructure } from "./helpers/utilities";

import { testQuestions, diagnosisQuestion } from "./data/testQuestions";

function App() {
  const [emailIsAuthorized, setEmailIsAuthorized] = useState(false);
  const [score, setScore] = useState(0)
  const [stage, setStage] = useState(0);

  //User information
  const [userInfo, setUserInfo] = useState({
    email: null,
    first: null,
    last: null,
  });

  //User test choices
  const [userChoices, setUserChoices] = useState(
    generateResultStructure(testQuestions.length)
  );

  //Backend related
  const [userHasBestPlan, setUserHasBestPlan] = useState(true);

  //Status
  //0 === uninitialized
  //1 === successful upload
  //2 === error
  const [uploadStatus, setUploadStatus] = useState(0);

  //Important for the transition effect
  useEffect(() => {
    activateScreenSizeChangeDetection();
  }, []);

  //Update user choices
  const updateContext = useCallback(
    (questionIndex, questionChoice) => {
      const newState = userChoices.map((obj) => {
        if (obj.id === questionIndex) {
          return { ...obj, choice: questionChoice };
        }
        return obj;
      });

      setUserChoices(newState);
    },
    [userChoices, setUserChoices]
  );

  const getContext = useCallback(
    () => ({
      array: userChoices,
      updateContext,
    }),
    [userChoices, updateContext]
  );

  const getScore = ()=> {
    const score = calculateScore(testQuestions, diagnosisQuestion, userChoices);
    setScore(score)
  }

  //Upload results to Firebase
  const saveData = async () => {
    const score = calculateScore(testQuestions, diagnosisQuestion, userChoices);
    setScore(score)

    //Upload results to ispeakdb
    try {
      await fetch("https://api.ispeak.team/api/Test/1234/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: userInfo.email,
          name: userInfo.first,
          lastname: userInfo.last,
          score,
        }),
      });
      setUploadStatus(1);
    } catch (error) {
      console.error(error);
      setUploadStatus(2);
    }
  };

  return (
    <div className="app">
      {!emailIsAuthorized ? (
        <UserInfoContext.Provider value={setUserInfo}>
          <Verification
            setEmailIsAuthorized={setEmailIsAuthorized}
            setUserHasBestPlan={setUserHasBestPlan}
          />
        </UserInfoContext.Provider>
      ) : (
        <UserChoicesContext.Provider value={getContext}>
          {stage === 0 ? (
            <Preliminaries setStage={setStage} />
          ) : stage === 1 ? (
            <DataLenghtContext.Provider value={testQuestions.length - 2}>
              <Questionnaire
                setStage={setStage}
                saveData={saveData}
                userHasBestPlan={userHasBestPlan}
                uploadStatus={uploadStatus}
              />
            </DataLenghtContext.Provider>
          ) : (
            <Scheduler
              userHasBestPlan={userHasBestPlan}
              getScore={getScore}
              saveData={saveData}
              uploadStatus={uploadStatus}
              score={score}
              setUploadStatus={setUploadStatus}
            />
          )}
        </UserChoicesContext.Provider>
      )}
    </div>
  );
}

export default App;
