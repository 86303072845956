import React, { useEffect, useMemo, useRef, useState } from "react"
import { collection, getDocs } from "firebase/firestore"
import { ReactComponent as Sync } from "../images/sync.svg"
import JuniorStars from "../images/junior-stars.svg"
import ExpertStars from "../images/expert-stars.svg"

//Calendy
import { InlineWidget } from "react-calendly"
import TopicCard from "./TopicCard"
import { db } from "../firebase"

const jrStars = [1, 1, 2, 0, 0]
const expertStars = [1, 1, 1, 1, 1]

const DatePickerContainer = ({ screen, goForward, loading, error, score }) => {
     const thisElement = useRef(null)
     const [height, setHeight] = useState(568)
     const [professors, setProfessors] = useState([])

     const getProfessors = async (scoreProf) => {
          const querySnapshot = await getDocs(collection(db, "testProfessors"))
          const professorsList = []
          querySnapshot.forEach((doc) => {
               professorsList.push(doc.data())
          })
          setProfessors(professorsList)
     }

     const professorsList = useMemo(() => {
          if (professors.length <= 0) return []
          const professorsByScore = professors.filter((prof) => {
               if (score <= 40) {
                    return prof.scoreTest === 1
               }
               return prof.scoreTest === 100
          })

          return professorsByScore
     }, [score, professors])

     const onFinishButtonClick = () => {
          goForward()
          setTimeout(() => {
               window.location.replace(`https://app.ispeak.team/`)
          }, 4000)
     }

     //To calculate the size of each screen
     useEffect(() => {
          setHeight(thisElement.current.clientHeight)

          setTimeout(() => {
               setHeight(thisElement.current.clientHeight)
          }, 100)
     }, [thisElement])

     useEffect(() => {
          getProfessors(score)
     }, [score])

     return (
          <div
               className='screen scheduler-dp'
               style={{
                    transform: `translateY(calc(${(screen - 1) * -1} * ${height}px))`,
                    zIndex: screen === 1 ? 100 : 0,
               }}
               ref={thisElement}
               tabIndex={-1}
          >
               <div className='container'>
                    {/* <img src={score <= 74 ? JuniorStars : ExpertStars} className='stars-header' alt='stars' /> */}
                    <h1 className='title-congrats'>¡Felicidades!</h1>
                    <p className='p-congrats'>
                         Queda un sólo paso, agenda tu entrevista con profesor en VIVO.
                    </p>
                    <div className='professors-list'>
                         {professorsList.map((prof) => (
                              <TopicCard
                                   key={prof.id}
                                   prof={prof}
                                   stars={score <= 74 ? jrStars : expertStars}
                              />
                         ))}
                    </div>
                    <p className='p-finish'>
                         Asegúrate de haber reservado tu cita antes de dar al botón «Finalizar»
                    </p>
                    <p className='p-finish' style={{ marginTop: "20px" }}>
                         Una vez que agendes, recibirás un correo con la fecha, hora y la sala de la
                         reunión.
                    </p>

                    <button
                         className='button btn-finish'
                         onClick={() => {
                              onFinishButtonClick()
                         }}
                    >
                         Finalizar
                         {loading && <Sync className='loader' />}
                    </button>

                    {error && (
                         <span className='error-msg'>
                              Ocurrió un error inesperado, comprueba tu conexión e inténtalo
                              nuevamente...
                         </span>
                    )}
               </div>
          </div>
     )
}

export default DatePickerContainer
