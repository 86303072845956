import React, { useState } from "react";

//Components
import TopComponent from "../TopComponent";
import AutoDiagnosis from "./AutoDiagnosis";
import Instructions from "../Instructions";

//Maximum number of scroll screens
const screenLimit = 1;

const Preliminaries = ({ setStage }) => {
  const [screen, setScreen] = useState(0);

  const goForward = () => {
    if (screen < screenLimit) {
      setScreen(screen + 1);
    } else {
      setStage(1);
    }
  };

  return (
    <TopComponent>
      <AutoDiagnosis screen={screen} goForward={goForward} />
      <Instructions screen={screen} goForward={goForward} />
    </TopComponent>
  );
};

export default Preliminaries;
