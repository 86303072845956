import { useMemo, useState } from "react"
import { CardProfData } from "./CardProfData"

const TopicCard = ({ prof, stars }) => {
     const [activeLink, setActiveLink] = useState("")

     const onSelectMeeting = (link) => {
          setActiveLink(link)
     }
     return (
          <div className={`topic-card ${activeLink === "" ? "" : "larger-topic"}`}>
               {activeLink === "" ? (
                    <CardProfData {...prof} stars={stars} onSelectMeeting={onSelectMeeting} />
               ) : (
                    <>
                         <iframe
                              src={`${activeLink}?embed=1&embedType=iframe`}
                              loading='lazy'
                              style={{
                                   border: "none",
                                   width: "100%",
                                   height: "544px",
                              }}
                              id='zcal-invite'
                         ></iframe>
                    </>
               )}
          </div>
     )
}

export default TopicCard
