import React, { useEffect, useRef } from "react";

import RadioType from "./RadioType";

const VideoType = ({ data, pause }) => {
  const iframe = useRef(null);

  //Pause video
  useEffect(() => {
    if (pause !== false) {
      iframe.current.contentWindow.postMessage(
        '{"event":"command","func":"pauseVideo","args":""}',
        "*"
      );
    }
  }, [pause]);

  return (
    <div className="video">
      <strong>Watch the video and choose the correct option</strong>

      <div className="iframe">
        <iframe
          ref={iframe}
          width="426"
          height="240"
          src={`${data.video_URL}?enablejsapi=1`}
          title="YouTube video player"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>

      <RadioType data={data} />
    </div>
  );
};

export default VideoType;
