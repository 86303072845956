import React, { useState } from "react";

//Components
import ImageType from "./types/ImageType";
import RadioType from "./types/RadioType";
import VideoType from "./types/VideoType";

import { ReactComponent as GoBack } from "../images/go-back.svg";
import { ReactComponent as Sync } from "../images/sync.svg";

const Question = ({
  data,
  goForward,
  goBack,
  disabled = false,
  loading,
  screen,
}) => {
  const typeOfQuestion = data.type;

  const [pause, setPause] = useState(false);

  //Video type question only
  const pauseVideo = () => {
    setPause(Date.now());
  };

  const nextQuestion = () => {
    if (typeOfQuestion === "video") {
      pauseVideo();
    }
    goForward();
  };

  const prevQuestion = () => {
    if (typeOfQuestion === "video") {
      pauseVideo();
    }
    goBack();
  };

  return (
    <div className="question">
      {data.related && (
        <strong>This question is related to the previous one</strong>
      )}

      {typeOfQuestion === "video" ? (
        <VideoType data={data} pause={pause} />
      ) : typeOfQuestion === "image" ? (
        <ImageType data={data} />
      ) : (
        <RadioType data={data} />
      )}

      <div className="buttons">
        <button
          className="button"
          tabIndex={
            data.id === 0
              ? screen === data.id
                ? 0
                : -1
              : screen === data.id - 1
              ? 0
              : -1
          }
          onClick={nextQuestion}
          disabled={disabled}
        >
          Siguiente
          {loading && <Sync className="loader" />}
        </button>

        {/* If you can go back and it is not the first question */}
        {goBack && data.id !== 1 && (
          <button
            className="button back"
            tabIndex={screen === data.id - 1 ? 0 : -1}
            onClick={prevQuestion}
            disabled={disabled}
          >
            <GoBack />
          </button>
        )}
      </div>
    </div>
  );
};

export default Question;
