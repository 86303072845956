import React from "react";

const TopComponent = ({ children }) => {
  return (
    <div className="top-component">
      <div className="top-container">{children}</div>
    </div>
  );
};

export default TopComponent;
